<template>
<div class="carTitle">
  <h1 class="cartTitle">Your Cart</h1>
<div class="wrapper">
  <div class="products">
      <div class="product" v-for="(product, index) in cart" :key="product.id">
        <div class="info">
          <h1>{{product.name}}</h1>
          <p>{{product.country}}</p>
        </div>
        <div class="image">
          <img v-bind:src="'/images/products/'+product.image">
        </div>
        <div class="price">
          <h2>{{product.price}}</h2>
          <button @click="removeCartItem(index)" class="auto">Remove from Cart</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Cart-page',
    props: {
    products: Array
  },
  computed: {
    cart() {
      return this.$root.$data.cart;
    }
  },
  methods: {
    removeCartItem(index) {
      this.$root.$data.cart.splice(index, 1);
    },
  },
}
</script>

<style scoped>
.cartTitle {
  display: flex;
  justify-content: center;
  margin: 1em;
  padding: 1em;
  background-color: #F2921D;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.products {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.product {
  margin: 10px;
  margin-top: 50px;
  width: 200px;
}

.product img {
  border: 2px solid #333;
  height: 250px;
  width: 200px;
  object-fit: cover;
}

.product .image {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.info {
  background: #F2921D;
  color: #000;
  padding: 10px 30px;
  height: 80px;
}

.info h1 {
  font-size: 16px;
}

.info h2 {
  font-size: 14px;
}

.info p {
  margin: 0px;
  font-size: 10px;
}


.price {
  display: flex;
}

button {
  height: 50px;
  background: #000;
  color: white;
  border: none;
}

.auto {
  margin-left: auto;
}
</style>